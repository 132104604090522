@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
  
  *{
    font-family: 'Inter', sans-serif;
}
p{
  margin: 0;
  padding: 0;
}
.table-heading {
    font-size: 24px;
    color: #333;
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .custom-table th, .custom-table td {
    padding: 8px 16px;
    border: 1px solid #ddd;
  }
  
  .custom-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .custom-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .custom-table tbody tr:hover {
    background-color: #f0f0f0;
  }
  .dis-flex{
    display: flex;
  }
  .sb-disflex{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .color{
    color: #EE3823;
  }
  .Header-appBar-3{
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3) !important;
  }
  .WithTheme-WithWidth-withRouter-Sidebar----drawerPaper-78{
    width: 208px !important;
  }
  .liststyle{
    padding: 0 10px !important;
    height: 38px !important;
  }
  .liststyle {
    background-color: transparent !important; /* Make the list transparent */
  }
  
  .liststyle:hover {
    background-color: transparent !important; /* Remove hover effect */
  }
  
  .liststyle.active > .flexSpacer {
    color: red; /* Change text color to red for selected list item */
  }
  .dropDownIcon{
    margin: 0 !important;
  }
  .addUserBtn{
    width: 200px !important;
    height: 40px !important;
    border-radius: 8px !important;
    /* background:  #EE3823 !important; */
    background:  #fff !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
    border: 1px solid #D0D5DD !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    /* color: #FFF !important; */
    color: #344054 !important;
    font-size: 14px !important;
    cursor: pointer !important;
    /* outline: none !important; */
    /* border: none !important; */
    padding: 0 16px !important;
    text-transform: none !important;
    font-weight: 600 !important;
}
.addUser{
    margin:20px 1rem  20px 1rem!important;
    padding: 10px 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px !important;
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
    border-radius: 3px;
}
.indicatorText{
  margin-right: auto!important;
  font-size: 18px !important;
  color:#EE3823;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-content {
  display: flex;
  align-items: center;
  gap: 3px;
}
.SearchBtn{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  height: 40px;
  width: 320px;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #D0D5DD);
  background: var(--Base-White, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-right: 12px;
 
}
.SearchBtn input{
  border: none;
  outline: none;
  width: 100%;
}
.SearchBtn img{
  margin-left: 14px;
}
.SearchBtn ::placeholder{
  font-size: 16px;
  color:#667085;
}
.tableContainer{
  padding: 0px 1rem !important;

}
.MuiPaper-elevation2-24{
  max-height: calc(100vh - 180px); 
  overflow: auto !important; 
  box-shadow: none !important;
  border: 1px solid #D0D5DD !important;
  border-radius: 3px ;
}
.MuiTableRow-head-118{
  height: 44px !important; 
  border-bottom: 1px solid var(--Gray-200, #EAECF0) !important;
  background: var(--Gray-50, #F9FAFB) !important;
  position: sticky !important;
top: 0 !important;
/* z-index: 10000000 !important; */
}
.MuiTableCell-head-121{
  font-size: 12px !important;
  color: #475467 !important;
font-weight: bold !important;
}
/* // toggle style <Start> */
.switch {
	position: relative;
	display: block;
	vertical-align: top;
	width: 42px;
	height: 25px;
	padding: 3px;
	border-radius: 5px;
	cursor: pointer;
	box-sizing:content-box;
}
.switch-input {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	box-sizing:content-box;
}
.switch-label {
	position: relative;
	display: block;
	height: inherit;
	font-size: 10px;
	text-transform: uppercase;
	background: #F2F4F7;
	/* border-radius: inherit; */
  border-radius: 15px;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
	box-sizing:content-box;
}
.switch-label:before, .switch-label:after {
	position: absolute;
	top: 50%;
	margin-top: -.5em;
	line-height: 1;
	-webkit-transition: inherit;
	-moz-transition: inherit;
	-o-transition: inherit;
	transition: inherit;
	box-sizing:content-box;
}
.switch-label:before {
	content: attr(data-off);
	/* right: -52px; */
	left: 48px;
	color: #00000063;
    font-weight: 600;
	/* // text-shadow: 0 1px rgba(255, 255, 255, 0.5); */
}
.switch-label:after {
	content: attr(data-on);
	left: 48px;
	/* color: #FFFFFF; */
	  /* color: #00000063; */
    color: #475467;
    font-weight: 600;
	/* // text-shadow: 0 1px rgba(0, 0, 0, 0.2); */
	opacity: 0;
}
.switch-input:checked ~ .switch-label {
	/* background: #23c552; */
	background: #EE3823;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.switch-input:checked ~ .switch-label:before {
	opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
	opacity: 1;
}
.switch-handle {
	position: absolute;
	top: 6px;
	left: 7px;
	width: 19px;
	height: 19px;
	background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
	background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
	border-radius: 100%;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
.switch-input:checked ~ .switch-handle {
	left: 22px;
	box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-label, .switch-handle {
	transition: All 0.3s ease;
	-webkit-transition: All 0.3s ease;
	-moz-transition: All 0.3s ease;
	-o-transition: All 0.3s ease;
}
  .action{
    margin-right: 13px;
}
.cursor{
  cursor: pointer;
}
.MuiList-padding{
padding: 0 !important;
}
.MuiCollapse-wrapperInner-255{
  background-color: transparent !important;
}
.SidebarItem-menuActive-234{
  background-color: transparent !important;
  color: #EE3823;
}
.mainContainer{
  padding: 20px 1rem;
  background: #f9f9f9;
}
.dis-flex{
  display: flex;
  align-items: center;
  gap: 4px;
}
.headTitle{
  padding: 0;
  color: #EE3823;
  font-size: 16px;
  font-weight: bold;
}
.displayFlex{
  display: flex;
  gap: 48px;
}
.ml--48{
  margin-left: -48px;
}
.commonInput{
  width: 254px;
  margin-top: 24px;
  
}

.m-b{
margin-bottom: 24px !important;
}
.m-t-24{
  margin-top: 20px;
}
.Icon-style{
  width: 18px !important;
  height: 18px !important;
  color: #000000;
}
.submitButton{
  /* margin-top: 24px; */
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  display: flex;
}
.btn{
  border-radius: 8px !important;
  border: 1px solid var(--primary-sd, #EE3823) !important;
  background: var(--primary-sd, #EE3823) !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  color: #FFFFFF !important;
  width: 95px !important;
  height: 43px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
  margin-right: 8px !important;

}
.cancelBtn{
  width: 95px !important;
  height: 43px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
  border-radius: 8px !important;
  border: 1px solid var(--Gray-300, #D0D5DD) !important;
  background: var(--Base-White, #FFF) !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  color: #344054 !important;
  margin-right: 8px !important;

}
.Content{
  padding: 0;
}
p{
  margin: 0;
  padding: 0 ;
}
.headTitlecon{
  padding: 24px 0px!important;
  color: #EE3823 !important;
  font-size: 16px !important;
}
.addbanner{
  width: 500px;
}
.line{
  margin: 24px 0px;
  background: #D0D5DD;
  height: 1px;
}
.contentHead{
  color: #667085;
  font-size: 14px;
}
.name{
  margin-top: 24px;
}
.Contentcon{
  padding-left: 16px;
}
::ng-deep .mat-form-field.mat-focused 
.mat-form-field-label{
  color: #98A2B3;
  font-size: 12px;
  font-weight: 500;
}
.nameInput{
  width: 497px;
}
.commonInput{
  width: 254px;
  margin-top: 24px;
}
.w-254{
  width: 254px;
  margin-top: 24px !important;

}
.w254{
  width: 254px;

}
.m-b-20{
  margin-bottom: 24px;
}
.subCon{
  margin-top: 30px;
}
.btn{
  border-radius: 8px !important;
  border: 1px solid var(--primary-sd, #EE3823) !important;
  background: var(--primary-sd, #EE3823) !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  color: #FFFFFF !important;
  width: 95px !important;
  height: 43px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
}
.info{
  cursor: pointer;
}
.m-48{
  margin-right: 48px !important;
}
.sm-b{
  margin-bottom: 24px !important;
}
.mailError {
  font-size: 12px;
  position: relative;
  color: #F04438;
  bottom: 10px;
  width: 254px;
}
.er{
  font-size: 12px;
  position: relative;
  color: #F04438;
  /* bottom: 10px; */
  width: 254px;
}
.m-t-10{
  margin-top: 10px;
}
.priorityBtn{
  margin-right: 8px !important;
  width: 170px !important;
  height: 40px !important;
  border-radius: 8px !important;
  border: 1px solid #D0D5DD !important;
  background:  #FFF !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #344054 !important;
  font-size: 14px !important;
  cursor: pointer !important;
  font-weight: 500 !important;
}
.post{
  font-size: 14px;
  color: var(--Gray-400, #3a3c41) !important;
  /* margin-bottom: 16px !important; */
}
.postDetail{
  font-size: 14px;
  color: var(--Gray-900, #101828) !important;
}
.m-24{
  margin-bottom: 24px;
}
.publishBtn{
  width: 200px !important;
  height: 43px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
  border-radius: 8px !important;
  border: 1px solid var(--Gray-300, #D0D5DD) !important;
  background: var(--Base-White, green) !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  color: #FFFFFF !important;
  margin-right: 8px !important;
}
.multiSelectWidth{
  width: 858px !important;
}
.bannerTableWidth{
  width: 80%;
}
.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.mainColor{
  color: #EE3823 !important;
}
.tableHead{
  font-size: 12px !important;
    color: #475467 !important;
    font-weight: bold !important;
}
.tableBody{
  color: #475467 !important; 
  font-size: 14px !important;
  /* height: 42px !important; */
}
.MuiTableCell-root{
  padding: 4px 0px 4px 24px !important;
}
.wholeTable{
   max-height: calc(100vh - 180px) !important;
    overflow: auto !important;
    box-shadow: none !important;
    border: 1px solid #D0D5DD !important;
    border-radius: 3px 
}
.priorityTable-inner{
  max-height: calc(100vh - 190px) !important;
  overflow-y: auto !important;
}
.priorityTableWithTabs-inner{
  max-height: calc(100vh - 272px) !important;
  overflow-y: auto !important;
}
.priorityTableWithDropdown-inner{
  max-height: calc(100vh - 342px) !important;
  overflow-y: auto !important;
}
.tr{
  height: 44px !important;
    border-bottom: 1px solid var(--Gray-200, #EAECF0) !important;
    background: var(--Gray-50, #F9FAFB) !important;
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0 !important;
    z-index: 100 !important;
}
/* loader */
/* .loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  border: 4px solid #f3f3f3; 
  border-top: 4px solid #EE3823; 
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; 
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

}
.loader {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  perspective: 800px;
}

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;  
}

.inner.one {
  left: 0%;
  top: 0%;
  animation: rotate-one 1s linear infinite;
  border-bottom: 3px solid #EE3823;
}

.inner.two {
  right: 0%;
  top: 0%;
  animation: rotate-two 1s linear infinite;
  border-right: 3px solid #EE3823;
}

.inner.three {
  right: 0%;
  bottom: 0%;
  animation: rotate-three 1s linear infinite;
  border-top: 3px solid #EE3823;
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
/*end*/
.p-t-14{
  position: relative;
  top: 14px;
}
.p-b-29{
  position: relative !important;
  bottom: 29px !important;
}
.checkbox-lable{
  color: #667085;
  font-size: 14px;
  margin-bottom: 6px !important;
  margin-top: 24px !important;
}
.upload-file {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #D0D5DD);
  background: var(--Base-White, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.upload-file-label {
  width: 115px;
  text-align: center;
  display: block;
  padding: 10px 0 10px 0;
  background-color: #FFF; 
  color: #344054;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #D0D5DD);
  margin-right: 10px;
}

.uploaded-file-link {
  position: relative;
  color: var(--Blue-700, #175CD3);
  text-decoration: underline; 
  margin-right: 10px;
  font-size: 14px;
  bottom: 6px !important;
}
.disflex{
  display: flex;
  flex-direction: column;
}
.closeUpload{
  color: #EE3823 !important;
  position: relative !important;
  top: 2px !important;
}
.imgError{
  top: 6px;
    font-size: 12px;
    position: relative;
    color: #F04438;
    width: 254px;
}
.imgInfoIcon{
  position: relative;
  top: 3px;
  left: 6px;
}
.imgInfo{
  width: 244px;
  margin-top: 8px !important;
  color: var(--Gray-700, #344054);
  font-size: 12px;
}
.p-21{
  position: relative;
  bottom: 21px;
}
.p-45{
  position: relative;
  bottom: 45px;
}
.p-30{
  position: relative;
  bottom: 30px;
}

.editor-container {
  width: 100%;
  height: 300px; /* Adjust height as needed */
}

.quill-editor {
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  font-size: 16px;
  width: 858px !important;
}

.ql-editor {
  min-height: 200px;
}
/* .quill > .ql-toolbar:first-child {
  display: none !important;
} */
.typeLabel{
  color: #667085;
    font-size: 14px;
}
.colStatus{
  color: #f5832a;
  font-size: 13px;
}
input{
  padding-right: 8px !important;
}
.lineb{
  margin: 24px 0 !important;
}
.p-t-3{
position: relative;
top: 3;
}
.head-name{
  color: var(--Gray-500, #667085);
  font-size: 14px;
  padding: 16px 0px !important;
}
.Contentdetail{
  color: var(--Gray-900, #101828);
  font-size: 14px;
  padding-bottom:16px !important ;
  width: 1000px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.flex-dir{
  display: flex;
  flex-direction: column;
}
.mar-10{
  margin-right: 10px;
}
.mat-divider-margin {
margin: 16px 0; /* You can adjust the margin as per your requirement */
}
.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjust the gap between images */
}

.Contentdetail-image {
  max-width: 100%;
  max-height: 86px; /* Set the maximum height for each image */
  width: auto;
  border: 1px solid rgba(128, 128, 128, 0.281);
}
.color{
  color: #EE3823 !important;
}
.slNo{
  flex: 0.2 !important;
}
:root {
  --Gray-200: #EAECF0;
  --Gray-50: #F9FAFB;
}

.priorityHeader{
  height: 44px !important;
  padding-left: 24px !important;
   border: 1px solid #c2c5cb !important;
    background: #eef0f2 !important;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
}
.priorityTitle{
width: 10%;
}
.priorityContent{

width: 100% !important;
cursor: grab;
}
.prioritylist{
  padding-left: 24px !important;
  border-bottom: 1px solid #c2c5cb !important;
  border-left: 1px solid #c2c5cb !important;
  border-right: 1px solid #c2c5cb !important;
  display: flex;
align-items: center;
height: 48px;
color:rgba(0, 0, 0, 0.87) !important;
font-size: 0.875rem !important;
height: 40px !important;
}
.w-100{
  width: 100% !important;
}
.w-80{
  width: 80% !important;
}
.SearchBtn form {
  width: 80% !important;
}
.priorityContainer{
  margin: 24px 0px;
  border-radius:3px !important ;
}
.colorText{
  color: #EE3823;
}
.MuiListItemIcon-root{
  min-width: 0px !important;
}
.tit{
  font-size: 15px !important;
  /* font-weight:  bold!important; */
  font-weight:  600 !important;
  color: #475467 !important;

}
.tit12{
  font-size: 14px !important;
}
.submenuCls{
  padding-left: 40px !important;
}
.menuIconNew{
  padding: 0px 10px 0px 0px !important;
  color: #EE3823 !important;
}
.userInfo{
  position: relative;
  text-align: right;
 
}
 .userName{
  font-size:14px;
  padding:0px !important;
  margin:0px !important;
}
.userRole{
  font-size:12px;
  padding:0px !important;
  margin:0px !important;
}
.signIncard{
  border-radius: 16px !important;
  border: 1px solid #EE3823 !important;
  padding: 10px !important;
}
.head{
  color: var(--Gray-900, #101828) !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  margin-bottom: 12px !important;
}
.subT{
  color: var(--Gray-700, #344054) !important;
  font-size: 14px !important;
}
.subTitle{
  text-align: center;
}
.mt9{
  margin-top: 9px !important;
}
.listItemTest{
  padding-left: 14px !important;
}
.loginbtn{
  margin-top: 20px !important;
  border-radius: 8px !important;
    border: 1px solid  #EE3823 !important;
    background:  #EE3823 !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
    height: 38px !important;
    font-size: 14px !important;
    color: var(--Base-White, #FFF) !important;
    font-weight: 600 !important;
    cursor: pointer !important;
}
.disableBtn{
  cursor: not-allowed !important;
  opacity: 0.5;
}
.displayFlexBanner{
  display: flex;
  width: 100%;
  justify-content: space-between;
 align-items: center;
}
.m-16{
  padding: 16px 24px !important;
}
.no-data{
  text-align: center;
}
.priorityTitleB{
  width: 25% !important;
}
.valueTitle{
  width: 55% !important;
}
.bannerList{
  width: 25%;
}
.w100{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
cursor: pointer;
}
.Ptabs{
  padding: 24px 0px !important;
}
.MuiBox-root{
  /* padding: 24px 0px !important; */
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.mb10{
  margin-bottom: 12px;
}
.p12{
  position: relative;
  bottom: 12px;
}
.MuiFormLabel-root.Mui-focused{
  color: #f5832a !important;
}
.MuiInput-underline:after{
  border-bottom: 2px solid #f5832a !important; /* Change the color to whatever you prefer */
}

.chipsStyle{
  border-radius: 16px;
    border: 1.5px solid var(--Gray-500, #667085);
    color: var(--Gray-700, #344054);
    font-size: 14px;
    background-color: transparent;
}
.chipsStyle{
  border-radius: 16px !important;
    border: 1.5px solid #667085 !important;
    color:#344054 !important;
    font-size: 14px !important;
    background-color: transparent !important;
    margin-bottom: 5px !important;
    margin-right: 5px !important;
}
.MuiChip-deleteIcon {
  /* color: #f5832a !important; Specify your desired color here */
  color: #00000061 !important; /* Specify your desired color here */
  width: 16px !important;
  height: 16px !important;
}
.even-row {
  background-color: #f5822a1a; /* Set background color for even rows */
}
.even-row:hover{
  font-weight: bold !important;
}
.odd-row:hover{
  font-weight: bold !important;
}

.odd-row {
  background-color: white; /* Set background color for odd rows */
}
.sortIcon{
  width: 20px !important;
    color: #EE3823 !important;
    height: 20px !important;
    position: relative;
    top: 5px;
}
.mt22{
  margin-top: 22px !important;
}
.rowDetIcon{
  position: relative;
  top: 17px;
}
.rowIN {
  display: flex;
  flex-direction: column;
}

.input-groups {
  display: flex;
  align-items: center;
}

.add-icon-container {
  display: flex;
  justify-content: flex-end;
}
.inactivebtn{
  border-radius: 50% !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
    color: #FFFFFF !important;
    width: 25px !important;
    height: 25px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    cursor: pointer !important;
    background-color: #EE3823 !important;
    margin: 8px 0px !important;
}
.textareaCls{
  margin-bottom: 24px;
  display: flex;
  flex-direction: column
}
.textareaCls textarea{
  margin-top: 8px;
  outline: none;
  border-radius: 8px;
  padding: 4px 4px;
}
.textareaCls textarea {
  border: 1px solid #cccccc; /* Default border style */
}
.textareaCls textarea:focus {
  border: 2px solid #f5832a;
}
.infopostion{
  left: 5px;
    position: relative;
    top: 4px;
}
.priorityTitleC{
  width: 25% !important;
}
.pb-0{
  padding-bottom: 0px !important;
}
.mb10{
  margin-bottom: 10 !important;
}

@media (min-width: 600px) {
  .MuiToolbar-regular {
      min-height: 54px;
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  overflow: scroll;
  background: rgb(237 237 237);
  box-shadow: inset 0 0 4px #b1acaccf;
}

::-webkit-scrollbar-thumb {
  background: #EE3823;
  border-radius: 6px;
}

textarea{
  color: #5a5a5a;
}

input{
  color: #5a5a5a !important;
}

#editor-wrapper{
  max-width: 858px;
}

.pointer-events-none{
  pointer-events: none !important;
}